html {
  height: 100%;
}

body {
  font-family: Montserrat;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  main {
    flex-grow: 1;
    padding: 16px;
  }

  li[role="menuitem"] {
    border-left: 3px solid white;
  }

  li[role="menuitem"]:hover {
    border-left: 3px solid #005BEA;
  }

  a {
    color: #005BEA;
  }

  .fullHeightTabPanel:not([hidden]) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .textButton {
    display: inline;
    padding: 0;
    text-transform: none;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    vertical-align: inherit;
  }

  .noUnderline {
    text-decoration: none;
  }

  .noPhraseBreak {
    white-space: nowrap
  }
}



@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
       transform: scale(0.8);
    }
    100% {
       transform: scale(1);
    }
}

.box-with-child-div div:first-child {
  padding: 0.3em;
}

@media only screen and (max-width: 900px) {
  #root {
  
    main {
      padding: 8px;
    }
  }
}